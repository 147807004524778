import React, { useEffect, useState } from "react";

function UploadExcel(props) {
	const [password, setPassword] = useState("")
	const [passwordValid, setPasswordValid] = useState(false)
	const [file, setFile] = useState(null);
	const [uploadButtonText, setUploadButtonText] = useState("Submit")

	var defaultUploadButtonStyle = {opacity: ".5", cursor: "pointer", width: "50%", borderRadius: "8px", marginBottom: "50px", fontSize: "16px", height: "50px", fontWeight: "600", backgroundColor: "#458cff", color: "white"}
	var readyUploadButtonStyle = {...defaultUploadButtonStyle, opacity: "1"}
	const [uploadButtonStyle, setUploadButtonStyle] = useState(defaultUploadButtonStyle)

	function addFiles() {
		document.querySelector("#file-input").click()
	}

	const handleFileChange = (e) => {
		setFile(e.target.files[0])
		setUploadButtonStyle(readyUploadButtonStyle)
	}

	const handleUpload = () => {
		const formData = new FormData();
		formData.append('file', file);
	
		fetch(`${window.location.protocol}//${window.location.host}/upload`, {
			method: 'POST',
			body: formData,
		})
		.then((response) => {
			if (response.status == 200) {
				setUploadButtonText("Upload Successful!")
			} else {
				setUploadButtonText("Upload Failed!")
			}
		})
		// .then(message => {
		// 	console.log(message); // Display the server response message
		// })
		// .catch(error => {
		// 	console.error('Error:', error);
		// });
	};

	function submitPassword() {
		return
	}

	// if (password == "") {
	// 	return (
	// 		<React.Fragment>
	// 			<div style={{display:"flex", flexDirection: "column", alignItems: "center", width: "100vw", height: "100vh", backgroundColor: "#f8f8f8"}}>
	// 				<div style={{backgroundColor: "white", width: "90vw", maxWidth: "500px", height: "700px", maxHeight: "70vh", marginTop: "10vh", borderRadius: "30px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.2)"}}>
	// 					<div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "100%"}}>
	// 						<img style={{marginTop: "30px", height: "80%"}} src="./logo.png"></img>
	// 						<p style={{marginTop: "10px", fontSize: "18px"}}>Product Code List Uploader</p>
	// 					</div>
	// 					<div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
	// 						<input style={{textAlign: "center", width: "70%", marginBottom: "20px", border: "1px solid #adadad", borderRadius: "8px", lineHeight: 2, fontSize: "30px"}} type="text" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)}></input>
	// 						<button style={{cursor: "pointer", width: "50%", borderRadius: "8px", marginBottom: "50px", fontSize: "16px", height: "50px", fontWeight: "600", backgroundColor: "#458cff", color: "white", backgroundColor: "#458cff"}} onClick={()=>{submitPassword()}}>Submit Password</button>
	// 					</div>
	// 				</div>

	// 			</div>
	// 		</React.Fragment>
	// 	  );
	// }

	return (
		<React.Fragment>
			<div style={{display:"flex", flexDirection: "column", alignItems: "center", width: "100vw", height: "100vh", backgroundColor: "#0E1016"}}>
				<div style={{backgroundColor: "rgb(34, 36, 42)", width: "90vw", maxWidth: "500px", height: "fit-content", marginTop: "10vh", borderRadius: "30px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.2)"}}>
					<div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "100%"}}>
						<img style={{marginTop: "30px", width: "95%"}} src="./logo.png"></img>
						<p style={{color: "white", marginTop: "10px", fontSize: "18px"}}>Product Code List Uploader</p>
					</div>
					<input id="file-input" type="file" accept=".xlsx" style={{ display: 'none' }} onChange={handleFileChange} />
					<div></div>
					<div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
						<button  onClick={addFiles} style={{cursor: "pointer", width: "50%", borderRadius: "8px", marginBottom: "10px", fontSize: "16px", height: "50px", fontWeight: "600", backgroundColor: "#cacaca"}}>Select File</button>
						{file ?
							<p>{file.name}</p>
							:
							<p> </p>
						}
					</div>
					<button onClick={handleUpload} style={uploadButtonStyle}>{uploadButtonText}</button>
				</div>
				<div style={{marginTop: 30}}>
					<a style={{color: "#0065d9", textDecoration: "none"}} href={`${window.location.protocol}//${window.location.host}/download`} target="_blank">Download Current Product Code List</a>
				</div>
			</div>
		</React.Fragment>
  	);
}

export default UploadExcel;
