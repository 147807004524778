import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CodeCheck from './CodeCheck'
import UploadExcel from "./UploadExcel";

import "./reset.css";
import "./App.css";

function App() {
	return (
		<div>
		<Router>
			<Routes>
				<Route path="/" element={<CodeCheck/>} />
			<Route path="/upload-excel" element={<UploadExcel/>} />
			</Routes>
		</Router>
	</div>
  	);
}

export default App;
