import React, { useEffect, useState } from "react";

function CodeCheck(props) {
	const [code, setCode] = useState("")
	const [buttonText, setButtonText] = useState("Check Code")

	var buttonDefaultStyle = {cursor: "pointer", width: "50%", borderRadius: "8px", marginBottom: "50px", fontSize: "16px", height: "50px", fontWeight: "600", backgroundColor: "#458cff", color: "white"}
	var buttonCodeValidStyle = {...buttonDefaultStyle, backgroundColor: "#00d200"}
	var buttonCodeInvalidStyle = {...buttonDefaultStyle, backgroundColor: "#ff3939"}
	const [buttonStyle, setButtonStyle] = useState(buttonDefaultStyle)

	function submit() {
		fetch(`${window.location.protocol}//${window.location.host}/check-code?code=${code}`)
			.then(response => response.json())
			.then((data) => {
				console.log(data)
				if (data.message == "code valid") {
					setButtonText("Code Valid!")
					setButtonStyle(buttonCodeValidStyle)
				} else if (data.message == "code checked") {
					setButtonText("Code Already Used!")
					setButtonStyle(buttonCodeInvalidStyle)
				} else if (data.message == "code invalid") {
					setButtonText("Code Invalid!")
					setButtonStyle(buttonCodeInvalidStyle)
				} else {
					setButtonText("Check Failed!")
					setButtonStyle(buttonCodeInvalidStyle)
				}
			})
			.catch(err => console.error(err));
	}



	useEffect(()=>{
		setButtonText("Check Code")
		setButtonStyle(buttonDefaultStyle)
	}, [code])

	return (
		<React.Fragment>
			<div style={{display:"flex", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: "#0E1016"}}>
				<div style={{backgroundColor: window.location !== window.parent.location ? "none" : "rgb(34, 36, 42)", width: "90vw", maxWidth: "500px", height: "fit-content", marginTop: "10vh", borderRadius: "30px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.2)"}}>
					<div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "100%"}}>
						<img style={{marginTop: "30px", width: "95%"}} src="./logo.png"></img>
						<p style={{color: "white", marginTop: "10px", marginBottom: 10, fontSize: "18px"}}>Product Authenticity Checker</p>
					</div>

					<div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
						<input style={{textAlign: "center", width: "70%", marginBottom: "20px", border: "1px solid #adadad", borderRadius: "8px", lineHeight: 2, fontSize: "20px"}} type="text" placeholder="Enter Code" value={code} onChange={e => setCode(e.target.value)}></input>
						<button style={buttonStyle} onClick={()=>{submit()}}>{buttonText}</button>
					</div>
				</div>
			</div>
		</React.Fragment>
  	);
}

export default CodeCheck;
